import React from 'react';
import Verify from './Verify';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <img className="logo" src="logo.png" alt=""/>
        <Verify/>
      </header>
    </div>
  );
}

export default App;
